import 'katex';
import { NgModule } from '@angular/core';
import { NgxQuillComponent } from './ngx-quill.component';

@NgModule({
  imports: [],
  declarations: [ NgxQuillComponent ],
  exports: [ NgxQuillComponent ]
})
export class NgxQuillModule { }
